	/* Keyframes for fade-in from top */
    @keyframes fadeInFromTop {
        0% {
          opacity: 0;
          transform: translateX(15%);
        }
        100% {
          opacity: 1;
          transform: translateX(0);
        }
      }
      
      /* Applying the animation to the image */
      .fade-in {
        animation: fadeInFromTop 1.8s ease-out forwards;
      }


      .triangle {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 100vw 80px 0;
        border-color: transparent transparent #F5F5F5 transparent;
      }

      .triangle1 {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 100vw 80px 0;
          border-color: transparent transparent  #F5F5F5  transparent;
      }
      
      /* Keyframes for the fade-in from bottom animation */
      @keyframes fadeInFromBottom {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    
    /* Styling for the web-spiral class */
    .web-spiral {
        animation: fadeInFromBottom 3s ease-out forwards;
        transition: opacity 7s ease-out;;
        opacity: 0;
    }



  /* Initial state of the elements */
	.fade-in-sequential {
		opacity: 0;
		transform: translateY(20px);
		transition: opacity 0.5s ease-out, transform 0.5s ease-out;
	}

	/* Class to apply when elements are visible */
	.fade-in {
		opacity: 1;
		transform: translateY(0);
	}



  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }





/* Define the transition effect */
.bg-pink-box {
 
    transition: all 0.5s ease-in-out;
} 

.bg-blue-box {
 
    transition: all 0.5s ease-in-out;
}





/* Forward transition */
.slide-enter {
  clip-path: inset(0 0 0 100%); /* Initially hide the content to the right */
  opacity: 0;
}

.slide-enter-active {
  clip-path: inset(0 0 0 0); /* Reveal the content */
  opacity: 1;
  transition: clip-path 0.5s ease-out, opacity 0.5s ease-out;
}

.slide-exit {
  clip-path: inset(0 0 0 0); /* Keep the content fully visible before exit */
  opacity: 1;
}

.slide-exit-active {
  clip-path: inset(0 100% 0 0); /* Hide the content to the right */
  opacity: 0;
  transition: clip-path 0.5s ease-in, opacity 0.5s ease-in;
}

/* Backward transition */
.slide-backward-enter {
  clip-path: inset(0 100% 0 0); /* Initially hide the content to the left */
  opacity: 0;
}

.slide-backward-enter-active {
  clip-path: inset(0 0 0 0); /* Reveal the content */
  opacity: 1;
  transition: clip-path 0.5s ease-out, opacity 0.5s ease-out;
}

.slide-backward-exit {
  clip-path: inset(0 0 0 0); /* Keep the content fully visible before exit */
  opacity: 1;
}

.slide-backward-exit-active {
  clip-path: inset(0 0 0 100%); /* Hide the content to the left */
  opacity: 0;
  transition: clip-path 0.5s ease-in, opacity 0.5s ease-in;
}









/* Add this CSS to your stylesheet */
.active-link {
  position: relative;
  font-weight: bold;
}

.active-link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;

}
.arrow-button {
  background: none;
  border: none;
  font-size:40px;
  color: #e91e63; /* Pink color */
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  
}







/* styles.css */

.tabsContainer {
  position: relative; /* Ensure the container is positioned */
}

.tab-button {
  position: relative;
  z-index: 10;
  padding: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  transition: color 0.3s ease, transform 0.3s ease;
}

.tab-button.active {
  color: #C62B6D;
  font-weight: bold;
}



.custom-phone-input {
  background-color: #7187ED !important;
  width: 100% !important; 
  padding: 0.5rem 0.75rem; 

}


  
.custom-phone-input2{
  background-color: white !important;
  width: 100% !important; 
  padding: 0.5rem 0.75rem; 


}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}




